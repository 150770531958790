import * as React from 'react'

import { Format } from '../../lib/format'
import { Vinyl, Cassette, Digital, Cd } from '../../lib/components/icon'

type Props = {
    children?: React.ReactNode
    format: Format
    width: number
    alt?: string
}

/**
 * A map of format names to the classname of the icon to display
 * the format with.
 */
const icons = {
    [Format.CD]: Cd,
    [Format.CDr]: Cd,
    [Format.DVD]: Cd,
    [Format.Cassette]: Cassette,
    [Format.VHS]: Cassette,
    [Format.UMatic]: Cassette,
    [Format.BetacamSP]: Cassette,
    [Format.ReelToReel]: Cassette,
    [Format.File]: Digital,
    [Format.Vinyl]: Vinyl,
    [Format.BoxSet]: Cd,
    [Format.BluRay]: Cd,
    [Format.AllMedia]: Cd,
    [Format.Unknown]: null,
}

export function Icon(props: Props): React.ReactElement {
    const { children, format, width, alt } = props
    const Component = icons[format] ?? icons[Format.Vinyl]

    return (
        <Component style={{ width }} aria-label={alt} alt={alt}>
            {children}
        </Component>
    )
}
