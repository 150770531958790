/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import * as React from 'react'

import { HTTPLink } from '../http-link'
import { ImageSourceFragment } from '../../../api/types'

export type PictureProps = React.ImgHTMLAttributes<HTMLImageElement> & {
    image: ImageSourceFragment
    preload?: boolean
    lazy?: boolean
    alt: string
    className?: string
    width?: number
    height?: number
    nsfw?: boolean
}

const enableWebp = false

export function Picture(props: PictureProps): React.ReactElement {
    const { image, className, alt, preload = false, lazy = true, width, height, ...rest } = props

    const preloads = preload && (
        <>
            {enableWebp && <HTTPLink rel='preload' as='image' type='image/webp' href={image.webpUrl} />}
            <HTTPLink rel='preload' as='image' type='image/jpeg' href={image.sourceUrl} />
        </>
    )

    const dims = dimensions(image, width, height)

    return (
        <picture className={className}>
            {preloads}
            {enableWebp && <source srcSet={image.webpUrl} type='image/webp' />}
            <img {...rest} src={image.sourceUrl} alt={alt} {...dims} loading={lazy ? 'lazy' : undefined} />
        </picture>
    )
}

export type Dimensions = {
    width: number
    height: number
}

export function dimensions(image: Dimensions, width: number = Infinity, height: number = Infinity): Dimensions {
    const ratio = image.width / image.height

    let w = image.width
    let h = image.height

    if (w > width) {
        w = width
        h = w / ratio
    }

    if (h > height) {
        h = height
        w = h * ratio
    }

    return { width: w, height: h }
}

export type PictureSetProps = {
    images: ImageSourceFragment[]
    sizes: string
    lazy?: boolean
    alt: string
    className?: string
}

export function PictureSet(props: PictureSetProps): React.ReactElement {
    const { images, sizes, className, alt, lazy = true } = props

    const webp = images
        .map((image: ImageSourceFragment): string => `${image.webpUrl} ${image.width || 150}w`)
        .join(', ')

    const jpeg = images
        .map((image: ImageSourceFragment): string => `${image.sourceUrl} ${image.width || 150}w`)
        .join(', ')

    return (
        <picture className={className}>
            {enableWebp && <source type='image/webp' srcSet={webp} />}
            <img src={images[0].sourceUrl} srcSet={jpeg} sizes={sizes} alt={alt} loading={lazy ? 'lazy' : undefined} />
        </picture>
    )
}
