export type Rel = 'preload' | 'modulepreload' | 'preconnect'

export type ResourcePurpose =
    | 'audio'
    | 'document'
    | 'embed'
    | 'fetch'
    | 'font'
    | 'image'
    | 'object'
    | 'script'
    | 'style'
    | 'track'
    | 'worker'
    | 'video'

export type Resource = {
    rel: Rel
    as?: ResourcePurpose
    type?: string
    media?: string
    href: string
}

// Build a Link header based on a resource definition
export function toLink(resource: Resource): string {
    let res = `<${resource.href}>; rel=${resource.rel}`
    if (resource.as) {
        res += `; as=${resource.as}`
    }
    if (resource.rel === 'preload' && resource.as === 'fetch') {
        res += '; crossorigin=anonymous'
    }
    if (resource.type) {
        res += `; type=${resource.type}`
    }
    if (resource.media) {
        res += `; media="${resource.media}"`
    }
    return res
}
