import * as React from 'react'
import { Trans } from '@lingui/macro'

import { useLogin } from '../../lib/login'
import { asFormat } from '../../lib/format'
import { Picture, PictureProps } from '../../lib/components/picture'
import { NSFWImagePlaceholderSmall } from '../../lib/components/nsfw-image-placeholder'
import { explicitMasterContent, explicitReleaseContent } from '../../lib/explicit-contentIds'

import { Icon } from './icon'
import css from './styles.css'

type Props = {
    format?: string
    alt: string
    image?: PictureProps['image']
    lazy?: boolean
    preload?: boolean
    height?: number
    width?: number
    nsfw?: boolean
    discogsId: number
}

export function ReleaseThumbnail(props: Props): React.ReactElement {
    const { image, format: formatName, alt, lazy, preload, width = 150, height = 150, nsfw = false, discogsId } = props
    const { loggedIn } = useLogin()

    const format = asFormat(formatName ?? 'Vinyl')

    if (!image) {
        return (
            <div className={css.fallback} style={{ width, height }}>
                <Icon format={format} alt={alt} width={width}>
                    <title>
                        <Trans>No image available; add an image</Trans>
                    </title>
                </Icon>
            </div>
        )
    }

    const blockForLoggedInUsers =
        explicitReleaseContent.includes(discogsId) && !explicitMasterContent.includes(discogsId)

    if ((nsfw && !loggedIn) || blockForLoggedInUsers) {
        return <NSFWImagePlaceholderSmall showButton={false} />
    }

    return (
        <div className={css.image}>
            <Picture image={image} lazy={lazy} alt={alt} preload={preload} width={width} height={height} />
        </div>
    )
}
