/**
 * Add delimiter between every element in arr.
 */

export function intersperse<T, U>(delimiter: U, arr: T[]): (T | U)[] {
    const res: (T | U)[] = []

    const arrayLength = arr.length
    for (let index = 0; index <= arrayLength - 1; index++) {
        res.push(arr[index])
        if (index < arrayLength - 1) {
            res.push(delimiter)
        }
    }

    return res
}
