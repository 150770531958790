import * as React from 'react'
import { Trans, plural, t } from '@lingui/macro'
import classnames from 'classnames'

import { useLocalisation } from '../../i18n'
import { Currency } from '../currency'
import { Link } from '../link'
import { Typography } from '@mui/material'

import { Currency as CurrencyProps } from '../../../api/types'

import css from './styles.css'

type Props = {
    buyUrl: string
    className?: string
    copiesForSale: number
    lowestPrice?: {
        converted: {
            amount: number
            currency: CurrencyProps
        }
    }
    isCollectionPage?: boolean
    onClick?: () => void
}

export function ForSale(props: Props): React.ReactElement {
    const { className, copiesForSale, lowestPrice, onClick, buyUrl, isCollectionPage } = props
    const { i18n } = useLocalisation()

    const copiesText = t(i18n)`${plural(copiesForSale.toString(), {
        one: '# copy',
        other: '# copies',
    })}`

    return (
        <div className={classnames(css.forSale, className)}>
            <Typography variant='labelXSmall' fontWeight={isCollectionPage ? 'normal' : 'bold'}>
                <Link href={buyUrl} onClick={onClick}>
                    {copiesText}
                </Link>
                {lowestPrice ? (
                    <>
                        {' '}
                        <Trans> from</Trans>{' '}
                        <span className={css.price}>
                            <Currency value={lowestPrice.converted} />
                        </span>
                    </>
                ) : null}
            </Typography>
        </div>
    )
}
