export enum Format {
    CD = 'CD',
    CDr = 'CDr',
    DVD = 'DVD',
    Cassette = 'Cassette',
    File = 'File',
    Vinyl = 'Vinyl',
    BoxSet = 'Box Set',
    BluRay = 'Blu-ray',
    ReelToReel = 'Reel-To-Reel',
    VHS = 'VHS',
    UMatic = 'U-Matic',
    BetacamSP = 'Betacam SP',
    AllMedia = 'All Media',
    Unknown = 'Unknown',
}

export function asFormat(str: string | undefined): Format {
    switch (str) {
        case 'CD':
        case 'CDr':
        case 'DVD':
        case 'Cassette':
        case 'File':
        case 'Vinyl':
            return Format[str]

        case 'BoxSet':
        case 'Box Set':
            return Format.BoxSet

        case 'Blu-ray':
        case 'Bluray':
            return Format.BluRay

        case 'Reel-To-Reel':
            return Format.ReelToReel
        case 'VHS':
            return Format.VHS
        case 'U-Matic':
            return Format.UMatic
        case 'Betacam SP':
            return Format.BetacamSP
        case 'All Media':
            return Format.AllMedia

        default:
            return Format.Unknown
    }
}
