import * as React from 'react'
import classnames from 'classnames'

import css from './styles.css'

type Props = React.TextareaHTMLAttributes<HTMLTextAreaElement> & { className?: string }

export const TextArea = React.forwardRef(function TextArea(
    props: Props,
    ref: React.Ref<HTMLTextAreaElement>,
): React.ReactElement {
    return <textarea {...props} className={classnames(css.textarea, props.className)} />
})
