import * as React from 'react'
import classnames from 'classnames'

import css from './styles.css'

type Props = {
    html?: string
    className?: string
}

export function Markup(props: Props): React.ReactElement {
    const { html, className } = props
    return <div className={classnames(css.markup, className)} dangerouslySetInnerHTML={{ __html: html ?? '' }} />
}
