import * as React from 'react'

import { Resource, toLink } from '../../../server/hints'

type Context = {
    // node linter is confusing this TS declaration as an experimental node feature.
    // eslint-disable-next-line n/no-unsupported-features/node-builtins
    headers: Headers
}

const context = React.createContext<Context | null>(null)

export function useContext(): Context | null {
    return React.useContext(context)
}

type Props = {
    // eslint-disable-next-line n/no-unsupported-features/node-builtins
    headers: Headers
    children: React.ReactNode | React.ReactNode[]
}

export function HTTPProvider(props: Props): React.ReactElement {
    const { headers, ...rest } = props

    const value = React.useMemo(() => ({ headers }), [headers])
    return <context.Provider value={value} {...rest} />
}

type HeaderProps = {
    overwrite?: boolean
    name: string
    value: string | number
}

export function HTTPHeader(props: HeaderProps): null {
    const context = useContext()

    if (context) {
        const { name, value, overwrite = false } = props
        if (overwrite) {
            context.headers.set(name, value.toString())
        } else {
            context.headers.append(name, value.toString())
        }
    }

    return null
}

export function HTTPLink(props: Resource): React.ReactElement {
    return <HTTPHeader name='Link' value={toLink(props)} />
}
