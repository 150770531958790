import * as React from 'react'
import { intersperse } from '../../lib/intersperse'

import { ReleaseHeaderFragment } from '../../api/types'
import { Link, Typography } from '@mui/material'

export type LabelRelationship = NonNullable<ReleaseHeaderFragment['labels']>[number]

type LabelLinkProps = {
    labels: LabelRelationship[]
    noCatNos?: boolean
    component?: React.ComponentType<{ children: React.ReactNode }>
}

export function ReleaseLabelLink(props: LabelLinkProps): React.ReactElement {
    const { labels, noCatNos = false, component: Component = React.Fragment } = props

    return (
        <>
            {intersperse(
                ', ',
                labels.map(function (rel: LabelRelationship): React.ReactElement | null {
                    const { label, catalogNumber, displayName } = rel
                    const name = displayName ?? label.name
                    const discogsId = 'discogsId' in label && label.discogsId
                    const href = 'siteUrl' in label && label.siteUrl
                    const link = href ? (
                        <Link variant='labelSmall' href={href}>
                            {name}
                        </Link>
                    ) : (
                        <span>{name}</span>
                    )
                    const key = `${discogsId || name}-${catalogNumber ?? '_'}`

                    return (
                        <Component key={key}>
                            {link}
                            {!noCatNos && catalogNumber && (
                                <Typography variant='labelSmall'>{` – ${catalogNumber}`}</Typography>
                            )}
                        </Component>
                    )
                }),
            )}
        </>
    )
}
